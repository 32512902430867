<template>
    <div class="kd-map-earth">
        <div class="ksem-search-box">
			<input class="kdem-search-input" placeholder="搜索地址" type="text" id="input_id">
            <!-- 多边形绘制 -->
            <a-space style="margin-left:10px;" v-if="drawType =='polygon'">
                <a-button @click="beginDraw" v-if="polygon.draw_polyline_status==0">开始绘制</a-button>
                <a-button @click="completeDraw" v-if="polygon.draw_polyline_status==2">完成绘制</a-button>
                <a-button @click="mapClear" v-if="polygon.draw_polyline_status==2">重新绘制</a-button>
            </a-space>

            <!-- 圆形绘制 -->
            <a-space style="margin-left:10px;" v-if="drawType =='circle'">
                <a-button @click="beginDraw" v-if="circle.draw_status==0">开始绘制</a-button>
                <a-button @click="completeDraw" v-if="circle.draw_status==2">完成绘制</a-button>
                <a-button @click="mapClear" v-if="circle.draw_status==2">重新绘制</a-button>
            </a-space>
		</div>
        <div id="allmap" :style="{width:'100%',height:mapHeight+'px'}"></div>
    </div>
</template>
<script>
import AMap from 'AMap'
import { onMounted, reactive, watch } from 'vue';
export default {
    name:'kd-map-earth',
    props:{
        mapWidth:{
            type:Number,
            default:500
        },
        mapHeight:{
            type:Number,
            default:500
        },
        isSatellite:{       //是否加载卫星地图
            type:Boolean,
            default:false,
        },
        drawType:{         //绘制图像类型 polygon多边形  circle圆形
            type:String,
            default:"polygon"
        },
        mapValue:{
            type:[Object,Array]
        }
    },
    setup(props,context) {
        const data = reactive({
            map:null,
            mouseTool:null, 
            marker:null,                //点标记对象
        })

        //多边形编辑信息
        const polygon = reactive({
            polygonEditer:null,     //多边形编辑对象
            draw_polyline_status:0,        //0未绘制 1绘制中 2绘制完成 3 绘制编辑完成
        })

        //圆形编辑信息
        const circle = reactive({
            circleEditor:null,
            draw_status:0, //绘制状态  //0未绘制 1绘制中 2绘制完成 3 绘制编辑完成
        })

        onMounted:{
            //此处延迟1秒初始化，等页面的元素渲染以后再进行初始化操作            
            let timer = setTimeout(() => {
                clearTimeout(timer)
                initMap()
            }, 1000);
        }

        //初始化操作
        function initMap(){

            var tiandituLayer = new AMap.TileLayer.Satellite({})
            //加载天地图的瓦片地址
            if( props.isSatellite ){
                tiandituLayer.setTileUrl('http://t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX=[z]&TILEROW=[y]&TILECOL=[x]&tk=68404e269217bb006fd5977410da694a')
                data.map = new AMap.Map('allmap', {
                    zoom:18,//级别
                    center: [116.397428, 39.90923],//中心点坐标
                    viewMode:'3D',//使用3D视图
                    layers:[tiandituLayer],
                });
            }else{
                data.map = new AMap.Map('allmap', {
                    zoom:18,//级别
                    center: [116.397428, 39.90923],//中心点坐标
                });
            }

            //定位
            AMap.plugin('AMap.Geolocation', function() {
                var geolocation = new AMap.Geolocation({
                    // 是否使用高精度定位，默认：true
                    enableHighAccuracy: true,
                    // 设置定位超时时间，默认：无穷大
                    timeout: 10000,
                    // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                    buttonOffset: new AMap.Pixel(10, 20),
                    //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    zoomToAccuracy: true,     
                    //  定位按钮的排放位置,  RB表示右下
                    buttonPosition: 'RB'
                })
                data.map.addControl(geolocation);
                geolocation.getCurrentPosition(function(status,result){
                    if(status=='complete'){
                        onComplete(result)
                    }else{
                        onError(result)
                    }
                });
                function onComplete (data) {
                    // data是具体的定位信息
                }
                function onError (data) {
                    // 定位出错
                }
            })


            //搜索
            var autoOptions = {
                // input 为绑定输入提示功能的input的DOM ID
                input: 'input_id'
            }
            AMap.plugin(['AMap.PlaceSearch','AMap.AutoComplete'], function(){
                var auto = new AMap.AutoComplete(autoOptions);
                var placeSearch = new AMap.PlaceSearch({
                    map: data.map
                });  //构造地点查询类
                auto.on("select", select);//注册监听，当选中某条记录时会触发
                function select(e) {
                    placeSearch.setCity(e.poi.adcode);
                    placeSearch.search(e.poi.name);
                }
            });
            //初始化渲染
            if( props.mapValue ) initRenderDraw()
        }

        watch(() => props.mapValue,(val) => {
            initRenderDraw()
        })

        //初始化渲染
        function initRenderDraw(){
            if( props.drawType == 'polygon'){
                if( polygon.draw_polyline_status == 0 && props.mapValue){
                    polygon.draw_polyline_status = 2
                    drawPolygon(props.mapValue)
                }
            }
            if( props.drawType == 'circle'){
                if( circle.draw_status == 0 && props.mapValue){
                    circle.draw_status = 2
                    drawCircle(props.mapValue.lnglat,props.mapValue.radius)
                }
            }
        }

        //开始绘制
        function beginDraw(){
            if( props.drawType =='polygon'){
                polygon.draw_polyline_status = 1  //  开始绘制线段
                AMap.plugin(["AMap.MouseTool"],function () {
                    data.mouseTool = new AMap.MouseTool(data.map)
                    drawPolyline()
                });
            }
            if( props.drawType == 'circle'){
                circle.draw_status = 1
                AMap.plugin(["AMap.MouseTool"],function () {
                    data.mouseTool = new AMap.MouseTool(data.map)
                    drawCircleCenter()  //确定圆心
                });
            }
            
        }

        //完成绘制
        function completeDraw(){
            if( props.drawType == 'polygon'){
                polygon.polygonEditer.close()       //完成绘制，关闭编辑对象
            }else{
                circle.circleEditor.close()
            }
        }

        //画线段
        function drawPolyline () {
            var polylinePoint = [],allMarker=[] ,infoWindow = null   //记录当前的点信息，用户绘制多边形
            data.mouseTool.polyline({
                strokeColor: "#3366FF", 
                strokeOpacity: 1,
                strokeWeight: 6,
                strokeStyle: "solid",
            })
            data.map.on("click",function(e){
                AMap.plugin(["AMap.CircleMarker"],function () {
                    //当绘制状态 = 1(绘制中)时 
                    if(polygon.draw_polyline_status == 1 ){
                        var marker = new AMap.CircleMarker({
                            center:[e.lnglat.lng,e.lnglat.lat],
                            radius:6,//3D视图下，CircleMarker半径不要超过64px
                            strokeColor:'white',
                            strokeWeight:2,
                            strokeOpacity:0.5,
                            fillColor:'rgba(0,0,255,1)',
                            fillOpacity:1,
                            zIndex:10,
                            bubble:true,
                            cursor:'pointer',
                            clickable: true
                        })
                        marker.setMap(data.map)
                        allMarker.push(marker)
                        polylinePoint.push([e.lnglat.lng,e.lnglat.lat])
                         //判断最后点击的一个点是不是开始的点
                        marker.on('click',function(e1){
                            data.mouseTool.close(true)
                            polygon.draw_polyline_status = 2
                            drawPolygon(polylinePoint)
                            data.map.remove(allMarker);       //移除所有点标记
                        })
                    }
                })
            })

            data.map.on('mousemove', function(e){
                //当绘制状态=1（绘制中）时，根据绘制的点的信息给予用户操作引导
                if( polygon.draw_polyline_status == 1 ){
                    var info = [];
                    if( polylinePoint.length == 0 ){
                        info.push("<div style=\"font-size:12px;\">开始绘制第一个点");    	
                    }else if(polylinePoint.length == 1 ){
                        info.push("<div style=\"font-size:12px;\">继续绘制下一个点");    
                    }else{
                        info.push("<div style=\"font-size:12px;\">继续绘制下一个点或点击起点完成绘制");    
                    }
                     infoWindow = new AMap.InfoWindow({
                        content:info.join("<br/>"),//信息窗体的内容
                        anchor: 'top-center',
                        offset:new AMap.Pixel(0, 20)
                    });
                    infoWindow.open(data.map,[e.lnglat.lng,e.lnglat.lat]); //信息窗体打开

                }else{
                    infoWindow.close()
                }
            });
        }

        //绘制多边形
        function drawPolygon(point){
            AMap.plugin(["AMap.Polygon",'AMap.PolygonEditor'],function () {
                let polygonObj = new AMap.Polygon({
                    path: point,
                    fillColor: '#ff780a',
                    strokeOpacity: 1,
                    fillOpacity: 0.3,
                    strokeColor: '#2b8cbe',
                    strokeWeight: 3,
                    strokeStyle: 'sliod',
                    strokeDasharray: [5, 5],
                });
                data.map.add(polygonObj);

                var polygonEditer = new AMap.PolygonEditor(data.map, polygonObj)
				polygonEditer.open()		//绘制的多边形打开编辑
                polygon.polygonEditer = polygonEditer

                //绘制完成，返回绘制的点的坐标和面积
                polygonEditer.on('end', function(event) {
                    if(polygon.draw_polyline_status == 2){
                        let markerPonit =[], polyPath = event.target.getPath()
                        polyPath.forEach(item=>{
                            markerPonit.push([item.lng,item.lat])
                        })
                        var area = (AMap.GeometryUtil.ringArea(markerPonit)).toFixed(2);
                        let result = {
                            point:markerPonit,
                            area:area
                        }
                        mapClear()
                        context.emit("getMapData",result)
                    }
                })
            })
        }

        //绘制圆形，先确定圆心
        function drawCircleCenter(){
            let infoWindow = null   
            //鼠标提示
            data.map.on('mousemove', function(e){
                
                if( circle.draw_status == 1 ){
                    var info = [];
                    info.push("<div style=\"font-size:12px;\">点击地图的上任意一点确定圆心");   
                    infoWindow = new AMap.InfoWindow({
                        content:info.join("<br/>"),//信息窗体的内容
                        anchor: 'top-center',
                        offset:new AMap.Pixel(0, 20)
                    });
                    infoWindow.open(data.map,[e.lnglat.lng,e.lnglat.lat]); //信息窗体打开
                }
                if( circle.draw_status == 2 ){
                    infoWindow.close()
                }
            });
            
            //使用地图点击事件，确定圆心位置
            data.map.on("click",function(e){
                if( circle.draw_status == 1 ){
                    drawCircle([e.lnglat.lng, e.lnglat.lat],1000)
                }
            })
        }

        //得到圆心，开始画圆
        function drawCircle(point,radius){
            AMap.plugin(["AMap.CircleEditor","AMap.Circle"],function(){
                var circleObj = new AMap.Circle({
                    center: point,
                    radius: radius, //半径
                    borderWeight: 3,
                    strokeColor: "#FF33FF", 
                    strokeOpacity: 1,
                    strokeWeight: 6,
                    strokeOpacity: 0.2,
                    fillOpacity: 0.4,
                    strokeStyle: 'dashed',
                    strokeDasharray: [10, 10], 
                    fillColor: '#1791fc',
                    zIndex: 50,
                })
                circleObj.setMap(data.map)
                data.map.setFitView([ circleObj ])
                //编辑圆
                var circleEditor = new AMap.CircleEditor(data.map, circleObj)
                circleEditor.open()
                circleEditor.on('end', function(event) {
                    //判断一下绘制状态 ，当此时绘制状态等于0时，表示用户需要重新绘制
                    if( circle.draw_status == 2){
                        let r = event.target.getRadius()
                        let result = {
                            center:event.target.getCenter(),  	//圆心位置
                            radius:r,							//圆半径
                            area:(3.14 * r*r).toFixed(2)	    //圆面积=π*r*r 平方米
                        }
                        data.map.clearMap();
                        mapClear()
                        context.emit("getMapData",result)
                    }
                })
                circle.circleEditor = circleEditor
                circle.draw_status = 2
            })
        }

        //清除覆盖物重新开始画
        function mapClear(){
            data.map.clearMap()
            circle.draw_status = 0
            polygon.draw_polyline_status = 0
            if( circle.circleEditor ) circle.circleEditor.close()
            if( polygon.polygonEditer ) polygon.polygonEditer.close()
        }

        return{
            data,
            circle,
            polygon,
            beginDraw,
            completeDraw,
            mapClear
        }
    },
}
</script>
<style lang="scss" scoped>
.ksem-search-box{
    position: absolute;
    margin-top: 10px;
    z-index: 999;
    padding-left: 24px;
    box-sizing: border-box;
    .kdem-search-input{
        width: 300px;
        z-index: 999;
        height: 34px;
        border-radius: 3px;
        border: 0;
        outline: none;
        padding-left: 12px;
    }
}
</style>